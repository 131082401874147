export default {
  route: {
    home: 'Halaman depan',
    music: 'Mengunggah musik',
    about: 'Tentang kita',
    upload: 'mengunggah',
    login: 'mendarat',
    privacy: 'Protokol privasi',
    userve: 'Perjanjian pengguna',
    recharge: 'Daya penuh',
    udesk: 'Customer Service',
    quickGuide: 'Panduan memulai dengan cepat, weelife',
    roomRewards: 'Join room for rewards',
    updateContent: 'UPDATE CONTENT',
    friendSpace: 'Penghargaan ruang teman',
    userGroup: 'Kelompok resmi pengguna',
    quickStartGuide: 'Panduan memulai dengan cepat, weelife',
    ktvParty: 'K-lagu pesta.',
    creatorPlatform: 'Platform Kreator',
    tutorial: 'Tutorial'
  },
  webMeta: {
    title: 'weelife',
    titleCompany: 'Newlang 新言',
    description: 'Weelife is a cutting-edge virtual reality voice social product tailored for Generation Z. Combining high-quality gamified virtual elements with voice social interactions, it offers an unparalleled ultra-immersive social experience on both mobile and VR platforms. In Weelife world, users express their personalities through stylish avatars. Rich social interaction features make connecting with new friends easy and enjoyable.',
    descriptionCompany: 'Weelife is a cutting-edge virtual reality voice social product tailored for Generation Z. Combining high-quality gamified virtual elements with voice social interactions, it offers an unparalleled ultra-immersive social experience on both mobile and VR platforms. In Weelife world, users express their personalities through stylish avatars. Rich social interaction features make connecting with new friends easy and enjoyable.',
    keywords: 'Weelife;Weelife app;Weelifeapp;weelife;weelife app;weelifeapp;WeelifeApp',
    keywordsCompany: '新言;新言官网;广州新言;新言信息;广州新言信息;新言信息科技;广州新言信息科技;广州新言信息科技有限公司;newlang;newlang官网;newlang app;newlang App;Newlang'
  },
  errorPage404: {
    errMsg404: 'Permintaan jaringan yang salah, sumber daya ini tidak ditemukan!',
    btnText: 'Buka aplikasi',
    MDialog: {
      headerText: 'Menyapu kode download weelife App',
      btnText: 'Download aplikasi'
    }
  },
  public: {
    downloading: 'Di unduh …',
    wait: 'Tunggu sebentar …',
    openFail: 'Aplikasi terbuka gagal untuk mencoba rejoined'
  },
  footer: {
    priv: 'Kebijakan privasi',
    userve: 'Perjanjian pengguna',
    contact: 'Hubungi kami',
    email: 'Email koperasi'
  },
  homePage: {
    content: {
      second: {
        content_1: 'Berpakaian seksi',
        content_2: 'Shiny kamu aku',
        content_3: 'Menampilkan diri yang cantik kepada teman-teman kecil'
      },
      third: {
        content_1: 'Ruang 3D',
        content_2: 'Sosial tanpa batas',
        content_3: 'Mengobrol dan bermain di ruang 3d dengan teman-teman kecil'
      },
      four: {
        content_1: 'Kelompok teman baru',
        content_2: 'Selalu menemani',
        content_3: 'Mengalami lingkungan teman-teman baru dalam 3D dan merasakan perbedaan bentuk sosial'
      },
      five: {
        content_1: 'K-lagu terkuat',
        content_2: 'Semua yang kau inginkan',
        content_3: 'Nyanyikan lagu di tempat KTV yang keren, saatnya beraksi!'
      },
      six: {
        content_1: 'Peta pencocokan',
        content_2: 'Tugasmu.',
        content_3: 'Dengan menggunakan peta dan imajinasi yang kreatif, anda dapat mengajak teman anda berkeliling'
      }
    }
  },
  login: {
    content: {
      content_1: 'Selamat datang di Weelife',
      content_2: 'Inilah beberapa perubahan wajah, obrolan dan persahabatan',
      content_3: 'Masuk dengan kata sandi akun',
      content_4: 'Log masuk',
      content_5: 'Silahkan masukkan email yang terdaftar',
      content_6: 'Tolong masukkan kode'
    },
    error: {
      content_1: 'Pengguna tidak terdaftar',
      content_2: 'Password salah'
    },
    success: {
      tip_1: 'Berhasil masuk'
    }
  },
  goods: {
    table: {
      title: 'Barang saya', // 我的商品
      content_1: 'Resource ID', // 资源ID
      content_2: 'Jenis Komponen', // 部件类型
      content_3: 'Waktu Upload', // 上传时间
      content_4: 'Status Upload', // 上传状态
      empty: 'Silakan login ke Platform Kreator WeeLife melalui PC untuk upload resource.',
      loading: 'Memuat...'
    },
    data: {
      content_2: 'Berhasil' // 成功
    }
  },
  music: {
    content: {
      content_1: 'Lagu yang diupload',
      content_2: 'Kepala',
      content_3: 'refresh',
      content_4: 'Mengunggah lagu'
    },
    table: {
      content_1: 'Judul lagu',
      content_2: 'penyanyi',
      content_3: 'ukuran',
      content_4: 'Waktu upload',
      content_5: 'Status upload',
      loading: 'Memuat...'
    },
    logout: {
      content_1: 'Log masuk keluar'
    },
    empty: {
      content_1: 'Sebuah lagu pengantar'
    },
    upload: {
      content_1: 'Kembali ke halaman sebelumnya',
      content_2: 'Judul lagu',
      content_tip_2: '（Harus diisi, tidak lebih dari 20 kata）',
      content_3: 'penyanyi',
      content_tip_3: '（Harus diisi, tidak lebih dari 20 kata）',
      content_4: 'Sumber musik',
      content_tip_4: '(harus diisi, berkas harus lebih dari 512k dan tidak lebih dari 20M, hanya didukung dalam format MP3)',
      content_5: 'Semantik musik',
      content_tip_5: '（Tak diragukan lagi）',
      content_6: 'Konfirmasi upload',
      content_7: 'Saya telah membaca dan setuju dengan tema berikut: Weelife party room music',
      content_8: '(baca perjanjian dan centang persetujuan)',
      content_9: 'Sukses mengunggah musik!!',
      content_10: 'Upload gagal silahkan upload ulang!!'
    },
    tips: {
      content_1: 'Silakan masukkan judul lagu',
      content_2: 'Silakan masukkan nama penyanyi',
      content_3: 'Pilih berkas',
      content_4: 'Berkas tertutup',
      content_5: 'Silahkan pilih sumeria musik',
      content_6: 'Konfirmasi upload',
      content_7: 'Harus memasukkan judul lagu',
      content_8: 'Kau harus memasukkan nama penyanyi',
      content_9: 'Pilih berkas',
      content_10: 'Berkas tertutup',
      content_11: 'Berkas tak terpilih',
      content_12: 'Nama berkas berisi karakter ilegal',
      content_13: 'Harus memilih jenis musik',
      content_14: 'Berkas tidak berguna, hanya format MP3'
    }
  },
  about: {
    intro: {
      title: 'Tentang weelife',
      content_1: 'Weelife also starred in a untuk digunakan secara eksklusif generasi Z (yang berada di garis depan, muda membangun keluar! Diputus tren sosial realita virtual produk sosial suara di permainan masa. — mat kerajinan, akan berkualitas tinggi yang permainan menjadi keras virtual image, adegan dengan sosial kebangsaan, suara maya, selesai pindahcomment VR yang menghidangkan sosial pengguna menikmati pengalaman sosial "tapi super" yang tidak pernah ada sebelumnya.',
      content_2: 'Dalam dunia Weelife, kepribadian seseorang dapat ditemukan dengan mencocokkan gambar indah dan kostum; Permainan interaksi sosial yang kaya dan menyenangkan yang memungkinkan teman baru untuk memecahkan es menjadi nyaman dan alami; Suasana sosial yang bervariasi, romantis, atau seru, untuk membatasi pengalaman bersama seorang teman; Suara nyata, gerakan fisik yang hidup membuat proses interaktif lebih emosional dan suhu; Bahkan bisa membangun tempat impian sendiri atau rumah untuk bersenang-senang dengan teman-teman. Tujuan Weelife adalah agar setiap pengguna di utopium yang dinamis dan lembut ini dapat menangkap gema suara mereka dan memenuhi jiwa mereka.',
      content_3: 'Weelife, produk yang masih muda, membutuhkan pertumbuhan berkelanjutan untuk melayani pengguna besar. Baik anda seorang pemain atau pencipta, di mana pun anda berada di dunia ini, kami ingin mendengarkan pendapat dan saran anda untuk membangun utopium bersama kami.'
    },
    culture: {
      title: 'Budaya perusahaan',
      content_1: 'Terbuka dan transparan',
      content_2: 'Sederhana dan rendah hati',
      content_3: 'Tantangan ekstrim',
      content_4: 'Kewirausahaan lapangan'
    },
    welfare: {
      title: 'Perawatan kesejahteraan'
    },
    contact: {
      title: 'Hubungi kami',
      content_1: 'Alamat ke rumah tentian no. 402, 238 west 7th avenue, guangzhou',
      content_2: 'Telepon：20- 668.37100',
      content_3: 'Email HR：'
    }
  },
  recharge: {
    header: 'Mengisi akun',
    title: {
      title_1: 'Silahkan masukkan nomor rekening anda',
      title_2: 'Pilih penyimpan data',
      title_3: 'Memeriksa stok'
    },
    box_1: {
      uid: 'ID akun',
      placeholder: 'Silahkan masukkan nomor rekening anda',
      btn: 'Verifikasi',
      switchText: 'Ganti akun'
    },
    bottom: {
      price: 'Jumlah hutang',
      pay: 'Membayar'
    },
    help: {
      header: 'Bagaimana cara memeriksa ID akun?',
      title_1: 'Buka aplikasi dan temukan interface saya',
      title_2: 'Klik sebelah ID anda',
      confirm_btn: 'Tahu!'
    },
    info: {
      header: 'Informasi lebih lanjut tentang pesanan',
      title: 'Mohon konfirmasikan isi ulang anda',
      nickname: 'Nama panggilan',
      recharge: 'Jumlah isi ulang',
      total_price: 'Total harga',
      cancel: 'Batalkan',
      confirm: 'Yakin'
    },
    loading: {
      pay_loading: 'Kiriman pesanan …'
    },
    error: {
      uid_not: 'ID nomor ini tidak ada'
    },
    order: {
      submit_loading: 'Kiriman pesanan …',
      inquire_loading: 'Pencarian pesanan...',
      success: 'Pembayaran berhasil',
      success_info: 'Pesanan top-up sukses! Terima kasih atas penggunaan dan dukungan Anda, silakan kembali ke Weelife untuk ceknya',
      timed_out: 'Pencarian pesanan timeout',
      timed_out_info: 'Pencarian pesanan timeout. Klik Konfirmasi untuk melanjutkan cari.',
      timed_out_info_two: 'Pencarian pesanan timeout. Silakan kembali ke Weelife-Pemberitahuan Sistem-Hubungi CS untuk menanyakan.'
    }
  },
  creator: {
    mygoods: 'Barang saya',
    tutorial: 'Tutorial',
    menswear: 'Pakaian Pria',
    womenswear: 'Pakaian Wanita',
    type_list: {
      TTF: 'Rambut',
      TTS: 'Aksesori Kepala',
      TMZ: 'Topi',
      TES: 'Anting',
      TMS: 'Aksesori Wajah',
      TYJ: 'Kacamata',
      XSY: 'Atasan',
      XWT: 'Jaket',
      XJS: 'Kalung',
      XBS: 'Aksesori Punggung',
      SST: 'Gloves',
      SSS: 'Aksesori Tangan',
      PKZ: 'Celana',
      PJSK: 'Legging',
      PYS: 'Aksesori Pinggang',
      JWZ: 'Socks',
      JXZ: 'Sepatu',
      PWB: 'Ekor',
      SJZ: 'Cincin',
      SBS: 'Aksesori Lengan',
      XLYQB: 'Jubah',
      XLYQG: 'Gaun',
      PQZ: 'Rok'
    }
  },
  creatorPlatform: {
    header: {
      logout: 'Keluar Login',
      tip: {
        text_1: 'Model pakaian harus dibuat sesuai dengan panduan, silakan lihat 【Tutorial】 untuk detailnya.',
        text_2: 'Lihat Tutorial',
        text_3: 'Model yang dikirim harus sesuai dengan 【Perjanjian Pengguna Platform】, jika tidak, tidak akan lolos verifikasi.',
        text_4: 'Lihat Perjanjian'
      }
    },
    table: {
      uploaded: 'Terupload',
      refresh: 'Refresh',
      upload_model: 'Upload Model',
      resourceId: 'Resource ID',
      type: 'Jenis Komponen',
      size: 'Size',
      uploadTime: 'Waktu Upload',
      status: 'Status Upload',
      status_suc: 'Berhasil',
      status_err: 'Gagal',
      empty: 'Belum ada pakaian yang diupload',
      loading: 'Memuat...'
    },
    upload_info: {
      back: 'Kembali',
      next: 'Lanjut',
      confirm: 'Konfirmasi',
      steps: {
        text_1: '01 Klasifikasi Gender',
        text_2: '02 Klasifikasi Komponen',
        text_3: '03 Upload Resource'
      },
      from: {
        title: 'Apa yang ingin Anda upload?',
        course: 'Lihat Tutorial',
        labels: {
          text_1: 'Pilih jenis yang ingin Anda buat',
          text_2: 'Pilih jenis pakaian yang Anda ingin buat'
        },
        dragger: {
          text_1: 'Tarik dan jatuhkan di sini',
          text_2: 'atau upload lampiran',
          text_3: '1.Silakan pastikan kategori pakaian sudah benar, karena ini akan memengaruhi tampilan item di mall.',
          text_4: '2.Silakan pastikan model sesuai dengan spesifikasi. Untuk detail lebih lanjut, silakan lihat',
          text_5: '【Panduan Produksi Pakaian】.'
        },
        upload_succ: 'Upload berhasil',
        upload_error: 'Upload gagal, silakan coba lagi',
        validator_1: 'Folder harus berisi direktori "Models" dan "Textures", dan folder tidak boleh kosong.',
        validator_2: 'Di dalam folder "Models", harap simpan file dengan ekstensi .FBX.',
        validator_3: 'Di dalam folder "Textures", harap simpan file dengan ekstensi .TGA.',
        go_back_stop: 'Sumber daya belum dikirim. Apakah Anda ingin membatalkan pengiriman?'
      }
    }
  },
  language: {
    zh: '中文',
    'zh-Hant': '中文繁体',
    en: 'English',
    ar: 'العربية',
    in: 'Indonesia',
    ms: 'Melayu',
    tl: 'Filipino',
    vi: 'Tiếng Việt',
    th: 'ไทย',
    tr: 'Türkçe'
  },
  download: {
    btn: 'Download sekarang'
  },
  popup: {
    cancel: 'Batal',
    confirm: 'Konfirmasi'
  },
  upload: {
    success: 'Terupload',
    loading: 'Sedang upload...',
    failed: 'Upload gagal, silakan coba lagi',
    repeat: 'Sedang upload! Jangan upload ulang!',
    click_retry: 'Tap untuk upload ulang',
    max_file: 'Jumlah file tidak boleh lebih dari {num} ya~',
    compressing: 'Compressing...',
    cancel: 'Batal',
    fail_message_1: 'Hanya satu folder yang bisa di-drag!',
    fail_message_2: 'Ukuran total file tidak boleh melebihi 1GB.'
  }
}
