<template>
  <footer>
    <div class="mobile-content" v-if="isShowMobile">
      <div>{{ $t('footer.email') + '：cuizhiwei@newlang.cn' }}</div>
      <div>{{ $t('footer.contact') + '：user_service@newlang.cn' }}</div>
      <div>
        <a href="/userve">
          <span class="link link_first">{{ $t('footer.userve') }}</span>
        </a>
        <span> | </span>
        <a href="/priv">
          <span class="link link_first">{{ $t('footer.priv') }}</span>
        </a>
      </div>
      <div v-if="isCompany">
        <a href="https://beian.miit.gov.cn/" target="_blank">
          <span>粤ICP备2021062879号-1</span>
        </a>
        <span> | </span>
        <span>广州新言信息科技有限公司</span>
      </div>
    </div>
  </footer>
</template>

<script lang='ts'>
import { useWindowSizeFn } from '@/hooks/useWindowSizeFn'
import { computed, defineComponent, ref } from 'vue'

export default defineComponent({
  name: 'MobileFooter',
  setup() {
    const contentWeight = ref(window.innerWidth)
    const isShowMobile = ref(false)

    useWindowSizeFn(
      () => {
        contentWeight.value = window.innerWidth
        if (contentWeight.value <= 825) {
          isShowMobile.value = true
          return
        }
        isShowMobile.value = false
      },
      200,
      { immediate: true }
    )

    const isCompany = computed(() => {
      return process.env.VUE_APP_USE === 'company'
    })

    return {
      isShowMobile,
      contentWeight,
      isCompany
    }
  }
})
</script>
<style lang='scss' scoped>
footer {
  width: 100%;
  padding-top: 2px;
  text-align: center;
  line-height: 60px;
  font-size: 28px;
}

.mobile-content {
  height: 100%;
  width: 100%;
}

@media screen and (max-width: 375px) {
  footer {
    padding-top: 0px;
    padding-bottom: 0px;
  }
}
</style>
