
export default {
  route: {
    home: 'Ana Sayfa',
    music: 'Müzik Yükle',
    about: 'Hakkımızda',
    upload: 'Yükle',
    login: 'Giriş Yap',
    privacy: 'Gizlilik Sözleşmesi',
    userve: 'Kullanıcı Sözleşmesi',
    recharge: 'Yükleme',
    udesk: 'Müşteri Hizmetleri',
    quickGuide: 'Weelife Hızlı Başlangıç Kılavuzu',
    roomRewards: 'Ödüller için odaya katıl',
    updateContent: 'İÇERİK GÜNCELLEME',
    friendSpace: 'Arkadaş Alanı Ödülleri',
    userGroup: 'Resmi Kullanıcı Grubu',
    quickStartGuide: 'Weelife Hızlı Başlangıç Kılavuzu',
    ktvParty: 'Karaoke Parti',
    creatorPlatform: 'Yaratıcılar Platformu',
    tutorial: 'Eğitim'
  },
  webMeta: {
    title: 'weelife',
    titleCompany: 'Newlang 新言',
    description: "Weelife, Z kuşağı gençleri için özel olarak tasarlanmış, çevrimiçi sosyal trendin ön sıralarında yer alan sanal gerçeklik sosyal bir uygulamadır. Yeni nesil oyun yapım teknolojisiyle desteklenen Weelife, yüksek kaliteli sanal karakterler, sanal sahneler ve sesli sohbeti birleştirerek mobil cihazlarda ve VR platformlarında sosyal kullanıcılara eşsiz bir 'süper gerçeklik' deneyimi sunmaktadır. Weelife dünyasında, şık görüntüler ve kıyafet kombinasyonlarıyla kişiliğinizi ve tarzınızı gösterebilirsiniz; zengin ve eğlenceli sosyal etkileşim oyunları, yeni arkadaşlıklar kurmak için rahat ve doğal bir sosyal etkileşim sağlar; farklı sosyal sahneler, bazen sıcak ve romantik, bazen heyecan verici ve maceralıdır, arkadaşlarınızla birlikte bu deneyimlerde bağ kurabilirsiniz; gerçek sesli etkileşimler ve canlı beden hareketleri, etkileşim sürecini daha duygusal ve sıcak hale getirir; hatta kendi hayalinizdeki sahneyi veya evinizi inşa edip arkadaşlarınızla birlikte eğlenceli zamanlar geçirebilirsiniz. Weelife'ın amacı, her bir kullanıcının bu canlı ve sıcak ütopik dünyada ruhsal bağlar kurabilmesini sağlamaktır.Weelife henüz yeni bir uygulama olup, daha geniş kullanıcı kitlesine daha iyi hizmet verebilmek için sürekli gelişmeye ihtiyaç duymaktadır.İster oyuncu ister yaratıcı olun, dünyanın neresinde olursanız olun, görüş ve önerilerinizi dinlemeyi, bizimle birlikte ideal ütopik dünyayı inşa etmenizi dört gözle bekliyoruz.",
    descriptionCompany: "Weelife, Z kuşağı gençleri için özel olarak tasarlanmış, çevrimiçi sosyal trendin ön sıralarında yer alan sanal gerçeklik sosyal bir uygulamadır. Yeni nesil oyun yapım teknolojisiyle desteklenen Weelife, yüksek kaliteli sanal karakterler, sanal sahneler ve sesli sohbeti birleştirerek mobil cihazlarda ve VR platformlarında sosyal kullanıcılara eşsiz bir 'süper gerçeklik' deneyimi sunmaktadır. Weelife dünyasında, şık görüntüler ve kıyafet kombinasyonlarıyla kişiliğinizi ve tarzınızı gösterebilirsiniz; zengin ve eğlenceli sosyal etkileşim oyunları, yeni arkadaşlıklar kurmak için rahat ve doğal bir sosyal etkileşim sağlar; farklı sosyal sahneler, bazen sıcak ve romantik, bazen heyecan verici ve maceralıdır, arkadaşlarınızla birlikte bu deneyimlerde bağ kurabilirsiniz; gerçek sesli etkileşimler ve canlı beden hareketleri, etkileşim sürecini daha duygusal ve sıcak hale getirir; hatta kendi hayalinizdeki sahneyi veya evinizi inşa edip arkadaşlarınızla birlikte eğlenceli zamanlar geçirebilirsiniz. Weelife'ın amacı, her bir kullanıcının bu canlı ve sıcak ütopik dünyada ruhsal bağlar kurabilmesini sağlamaktır.Weelife henüz yeni bir uygulama olup, daha geniş kullanıcı kitlesine daha iyi hizmet verebilmek için sürekli gelişmeye ihtiyaç duymaktadır.İster oyuncu ister yaratıcı olun, dünyanın neresinde olursanız olun, görüş ve önerilerinizi dinlemeyi, bizimle birlikte ideal ütopik dünyayı inşa etmenizi dört gözle bekliyoruz.",
    keywords: 'Weelife;Weelife app;Weelifeapp;weelife;weelife app;weelifeapp;WeelifeApp',
    keywordsCompany: 'Yeni kelimeler; Newlang resmi web sitesi; Guangzhou Newlang; Yeni Kelime Bilgisi; Guangzhou Newlang Bilgileri; Newlang Bilgi Teknolojisi; Guangzhou Newlang Bilgi Teknolojisi; Guangzhou Newlang Bilgi Teknolojileri Co, Ltd ; yeni dil; Newlang resmi web sitesi; Newlang Uygulaması; newlang Uygulaması; Yeni Lang'
  },
  errorPage404: {
    errMsg404: 'Ağ isteği hatası, bu kaynak bulunamadı.',
    btnText: 'Uygulamayı Aç',
    MDialog: {
      headerText: 'Weelife Uygulamasını QR Kodu ile İndir',
      btnText: 'Uygulamayı İndir'
    }
  },
  public: {
    downloading: 'İndiriliyor...',
    wait: 'Lütfen Bekleyin...',
    openFail: 'Uygulama açılmadı, tekrar başlatmayı deneyin'
  },
  footer: {
    priv: 'Gizlilik Politikası',
    userve: 'Kullanıcı Sözleşmesi',
    contact: 'Bize Ulaşın',
    email: 'İşbirliği E-posta adresi'
  },
  homePage: {
    content: {
      second: {
        content_1: 'Kişisel Tasarımlar',
        content_2: 'Parlıyoruz',
        content_3: 'En güzel halinizi arkadaşlarınıza gösterin.'
      },
      third: {
        content_1: '3D Alan',
        content_2: 'Sınırsız sosyal etkileşim',
        content_3: '"Arkadaşlarınızla birlikte 3D ortamda sohbet edip eğlenin.'
      },
      four: {
        content_1: 'Yeni Nesil Sosyal Çevre',
        content_2: 'Sosyal etkileşimde sınır yok',
        content_3: 'Yeni ve ilginç 3D arkadaş çevresini deneyimleyin, farklı sosyal etkileşim türünü hissedin.'
      },
      five: {
        content_1: 'Karaoke Yıldızı Benim',
        content_2: 'Ben Şarkı Kralıyım',
        content_3: 'KTV ortamında sesinizi sergileyin ve alkış toplayın!'
      },
      six: {
        content_1: 'Harita Resmi Ekle',
        content_2: 'Söz senin.',
        content_3: 'Hayal gücünüzü kullanarak yaratıcı ve sıradışı haritalar oluşturun, arkadaşlarınızı birlikte keşfe davet edin~'
      }
    }
  },
  login: {
    content: {
      content_1: "Weelife'a Hoş Geldiniz",
      content_2: 'Burada yüz tasarımı ve kıyafet değiştirme, sohbet ve arkadaşlık, havalı sahnelerle benzersiz bir sanal avatar oluşturabilirsiniz.',
      content_3: 'Hesap ve Şifre ile Giriş Yap',
      content_4: 'Giriş Yap',
      content_5: 'Lütfen kayıtlı e-posta adresinizi girin.',
      content_6: 'Lütfen şifreyi girin.'
    },
    error: {
      content_1: 'Kullanıcı kayıtlı değil.',
      content_2: 'Girdiğiniz şifre yanlış.'
    },
    success: {
      tip_1: 'Giriş Başarılı'
    }
  },
  goods: {
    table: {
      title: 'Ürünlerim',
      content_1: "Kaynak ID'si",
      content_2: 'Parça Türü',
      content_3: 'Yükleme Zamanı',
      content_4: 'Yükleme Durumu',
      empty: "Lütfen bilgisayar üzerinden Weelife Yaratıcı Yönetim Paneli'ne giriş yaparak kaynakları yükleyin.",
      loading: 'Yükleniyor...'
    },
    data: {
      content_2: 'Başarılı'
    }
  },
  music: {
    content: {
      content_1: 'Yüklenen Şarkılar',
      content_2: 'Parça ',
      content_3: 'Yenile',
      content_4: 'Şarkı Yükle'
    },
    table: {
      content_1: 'Şarkı Adı',
      content_2: 'Şarkıcı',
      content_3: 'Boyut',
      content_4: 'Yükleme Zamanı',
      content_5: 'Yükleme Durumu',
      loading: 'Yükleniyor...'
    },
    logout: {
      content_1: 'Çıkış Yap'
    },
    empty: {
      content_1: 'Yüklenen Şarkı Yok'
    },
    upload: {
      content_1: 'Bir Önceki Sayfaya Geri Dön',
      content_2: 'Şarkı Adı',
      content_tip_2: '(Zorunlu, 20 Karakteri Aşamaz)',
      content_3: 'Şarkıcı',
      content_tip_3: '(Zorunlu, 20 Karakteri Aşamaz)',
      content_4: 'Müzik Kaynağı',
      content_tip_4: "(Doldurulması Zorunludur, Dosya 512KB'den büyük ve 20MB'den küçük olmalı, sadece MP3 formatı desteklenmektedir)",
      content_5: 'Müzik Dili',
      content_tip_5: '(Seçim Zorunludur)',
      content_6: 'Yüklemeyi Tamamla',
      content_7: "Aşağıdaki 'Weelife Parti Odası Müzik Yükleme ve Paylaşma Hizmeti Kuralları'nı dikkatlice okudum ve kabul ediyorum.",
      content_8: '(Lütfen sözleşmeyi okuyun ve kabul ettiğinizi işaretleyin)',
      content_9: 'Müzik Yükleme Başarılı!',
      content_10: 'Yükleme Başarısız, Lütfen Tekrar Yükleyin!'
    },
    tips: {
      content_1: 'Lütfen Şarkı Adını Girin',
      content_2: 'Lütfen Şarkıcı Adını Girin',
      content_3: 'Dosya Seç',
      content_4: 'Dosyayı Üzerine Yaz',
      content_5: 'Lütfen Müzik Dilini Seçin',
      content_6: 'Yüklemeyi Tamamla',
      content_7: 'Şarkı adını girmek zorunludur.',
      content_8: 'Sanatçı adını girmek zorunludur.',
      content_9: 'Dosya Seç',
      content_10: 'Dosyayı Üzerine Yaz',
      content_11: 'Dosya seçilmedi',
      content_12: 'Dosya adında kullanılması yasak karakterler (Semboller) bulunuyor.',
      content_13: 'Müzik türünü seçmek zorunludur.',
      content_14: 'Dosya formatı uygun değil, yalnızca MP3 formatı destekleniyor.'
    }
  },
  about: {
    intro: {
      title: 'Weelife Hakkında',
      content_1: "Weelife, Z kuşağı gençleri için özel olarak tasarlanmış, çevrimiçi sosyal trendin ön sıralarında yer alan sanal gerçeklik sosyal bir uygulamadır. Yeni nesil oyun yapım teknolojisiyle desteklenen Weelife, yüksek kaliteli sanal karakterler, sanal sahneler ve sesli sohbeti birleştirerek mobil cihazlarda ve VR platformlarında sosyal kullanıcılara eşsiz bir 'süper gerçeklik' deneyimi sunmaktadır. ",
      content_2: "Weelife dünyasında, şık görüntüler ve kıyafet kombinasyonlarıyla kişiliğinizi ve tarzınızı gösterebilirsiniz; zengin ve eğlenceli sosyal etkileşim oyunları, yeni arkadaşlıklar kurmak için rahat ve doğal bir sosyal etkileşim sağlar; farklı sosyal sahneler, bazen sıcak ve romantik, bazen heyecan verici ve maceralıdır, arkadaşlarınızla birlikte bu deneyimlerde bağ kurabilirsiniz; gerçek sesli etkileşimler ve canlı beden hareketleri, etkileşim sürecini daha duygusal ve sıcak hale getirir; hatta kendi hayalinizdeki sahneyi veya evinizi inşa edip arkadaşlarınızla birlikte eğlenceli zamanlar geçirebilirsiniz. Weelife'ın amacı, her bir kullanıcının bu canlı ve sıcak ütopik dünyada ruhsal bağlar kurabilmesini sağlamaktır. ",
      content_3: 'Weelife henüz yeni bir uygulama olup, daha geniş kullanıcı kitlesine daha iyi hizmet verebilmek için sürekli gelişmeye ihtiyaç duymaktadır. İster oyuncu ister yaratıcı olun, dünyanın neresinde olursanız olun, görüş ve önerilerinizi dinlemeyi, bizimle birlikte ideal ütopik dünyayı inşa etmenizi dört gözle bekliyoruz.'
    },
    culture: {
      title: 'Şirket Kültürü',
      content_1: 'Açık ve şeffaf',
      content_2: 'Sade ve alçakgönüllü',
      content_3: 'Zirveye meydan okuyan',
      content_4: 'Gerçekten çalışarak girişimcilik yapan'
    },
    welfare: {
      title: 'Faydalar'
    },
    contact: {
      title: 'Bize Ulaşın',
      content_1: 'Adres: 402 Qintian Binası, No. 238, Zhongshan Bulvarı, Tianhe Bölgesi, Guangzhou',
      content_2: 'Telefon: 020-66837100',
      content_3: 'HR E-Posta Adresi：'
    }
  },
  recharge: {
    header: 'Hesap bakiye yüklemesi',
    title: {
      title_1: "Lütfen hesap ID'nizi girin",
      title_2: 'Yükleme yöntemi seçin',
      title_3: 'Yükleme tutarını seçin'
    },
    box_1: {
      uid: "Hesap ID'si",
      placeholder: "Lütfen hesap ID'nizi girin",
      btn: 'Doğrulama',
      switchText: 'Hesap Değiştir'
    },
    bottom: {
      price: 'Ödenmesi gereken tutar',
      pay: 'Ödeme'
    },
    help: {
      header: "Hesap ID'si nasıl görüntülenir?",
      title_1: 'Weelife uygulamasını açın, ardından 【Benim】 sayfasına girin.',
      title_2: "ID'nizin yanındaki 【Kopyala】 tuşuna tıklayın.",
      confirm_btn: 'Anladım!'
    },
    info: {
      header: 'Sipariş Detay Bilgisi',
      title: 'Lütfen yükleme bilgilerinizi onaylayın.',
      nickname: 'Takma Ad',
      recharge: 'Bakiye Yükleme Miktarı',
      total_price: 'Toplam Fiyat',
      cancel: 'İptal Et',
      confirm: 'Onayla'
    },
    error: {
      uid_not: "Bu hesap ID'si mevcut değil."
    },
    order: {
      submit_loading: 'Sipariş gönderiliyor...',
      inquire_loading: 'Sipariş sorgulanıyor...',
      success: 'Ödeme Başarılı',
      success_info: "Sipariş yüklemesi başarıyla tamamlandı! Kullanımınız ve desteğiniz için teşekkür ederiz, lütfen Weelife'a geri dönüp kontrol edin.",
      timed_out: 'Sipariş sorgulama zaman aşımına uğradı',
      timed_out_info: 'Sipariş sorgulama süresi doldu, onayladıktan sonra sorgulamaya devam edin.',
      timed_out_info_two: "Sipariş sorgulama süresi doldu, lütfen Weelife'a geri dönün - Sistem Bildirimi - Müşteri Hizmetleri ile iletişime geçerek sorgulama yapın."
    }
  },
  creator: {
    mygoods: 'Ürünlerim',
    tutorial: 'Eğitim',
    menswear: 'Erkek Giyim',
    womenswear: 'Kadın Giyim',
    type_list: {
      TTF: 'Saç',
      TTS: 'Kafa Aksesuarları',
      TMZ: 'Şapka',
      TES: 'Küpe',
      TMS: 'Yüz Aksesuarları',
      TYJ: 'Gözlük',
      XSY: 'Üst Giyim',
      XWT: 'Kaban',
      XJS: 'Kolye',
      XBS: 'Sırt Aksesuarları',
      SST: 'Eldiven',
      SSS: 'El Takısı',
      PKZ: 'Pantolon',
      PJSK: 'Dar Pantolon',
      PYS: 'Bel Aksesuarları',
      JWZ: 'Çorap',
      JXZ: 'Ayakkabı',
      PWB: 'Kuyruk',
      SJZ: 'Yüzük',
      SBS: 'Kol Aksesuarları',
      XLYQB: 'Cilbab',
      XLYQG: 'Elbise',
      PQZ: 'Etek'
    }
  },
  creatorPlatform: {
    header: {
      logout: 'Çıkış Yap',
      tip: {
        text_1: "1. Giysi modeli standartlara göre yapılmalıdır, detaylar için lütfen [Eğitim Rehberi]'ne göz atın.",
        text_2: 'Eğitim rehberini incele.',
        text_3: "2. Gönderilen model, [Platform Kullanıcı Sözleşmesi]'ne uygun olmalıdır, aksi takdirde onaylanamaz.",
        text_4: 'Kullanıcı sözleşmesini incele.'
      }
    },
    table: {
      uploaded: 'Yükleme tamamlandı',
      refresh: 'Yenile',
      upload_model: 'Model yükle',
      resourceId: "Kaynak ID'si",
      type: 'Parça Türü',
      size: 'Boyut',
      uploadTime: 'Yükleme Zamanı',
      status: 'Yükleme Durumu',
      status_suc: 'Başarılı',
      status_err: 'Başarısız',
      empty: 'Henüz yüklenmiş kıyafet yok',
      loading: 'Yükleniyor...'
    },
    upload_info: {
      back: 'Sonraki Adım',
      next: 'Önceki Adım',
      confirm: 'Onayla',
      steps: {
        text_1: '01 Cinsiyet Kategorisi',
        text_2: '02 Parça Kategorisi',
        text_3: '03 Kaynak Yükleme'
      },
      from: {
        title: 'Ne yüklemek istersiniz?',
        course: 'Eğitim rehberini incele.',
        labels: {
          text_1: 'Oluşturmak istediğiniz türü seçin',
          text_2: 'Oluşturmak istediğiniz kıyafet türünü seçin'
        },
        dragger: {
          text_1: 'Buraya sürükleyin.',
          text_2: 'veya ek dosya yükleyin',
          text_3: '1. Lütfen yüklediğiniz kıyafet kategorisinin doğru olduğundan emin olun. Bu, ürünün mağazada nasıl görüntüleneceğini etkileyebilir.',
          text_4: '2. Lütfen modelin standartlara uygun olduğundan emin olun, detaylar için lütfen göz atın:',
          text_5: 'Kıyafet Standartları'
        },
        upload_succ: 'Yükleme Başarılı',
        upload_error: 'Yükleme başarısız, lütfen tekrar yükleyin.',
        validator_1: "Klasörde 'Modeller' ve 'TDokular' dizinleri bulunmalı ve boş olmamalıdır.",
        validator_2: 'Modeller dosya klasöründe, lütfen FBX uzantılı dosyaları saklayın.',
        validator_3: 'Dokular dosya klasöründe, lütfen TGA uzantılı dosyaları saklayın.',
        go_back_stop: 'Kaynak gönderilmedi, vazgeçilsin mi?'
      }
    }
  },
  language: {
    zh: '中文',
    'zh-Hant': '中文繁体',
    en: 'English',
    ar: 'العربية',
    in: 'Indonesia',
    ms: 'Melayu',
    tl: 'Filipino',
    vi: 'Tiếng Việt',
    th: 'ไทย',
    tr: 'Türkçe'
  },
  download: {
    btn: 'Hemen İndir'
  },
  popup: {
    cancel: 'İptal Et',
    confirm: 'Onayla'
  },
  upload: {
    success: 'Yükleme tamamlandı',
    loading: 'Yükleniyor...',
    failed: 'Yükleme başarısız oldu, lütfen tekrar yükleyin!',
    repeat: 'Yükleniyor! Lütfen tekrar yüklemeyin!',
    click_retry: 'Tekrar yüklemek için tıklayın',
    max_file: 'Dosya sayısı {num} taneyi geçemez.',
    compressing: 'Sıkıştırılıyor...',
    cancel: 'İptal Et',
    fail_message_1: 'Sadece bir klasör sürükleyebilirsiniz!',
    fail_message_2: "Dosyanın toplam boyutu 1GB'ı geçemez."
  }
}
