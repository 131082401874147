export default {
  route: {
    home: 'Rumah',
    music: 'Muat naik muzik',
    about: 'Tentang kami',
    upload: 'Muat naik',
    login: 'Pendaratan',
    privacy: 'Perjanjian Privasi',
    userve: 'Perjanjian Pengguna',
    recharge: 'Cas semula',
    udesk: 'Customer Service',
    quickGuide: 'Panduan Mula Pantas WeeLife',
    roomRewards: 'Join room for rewards',
    updateContent: 'UPDATE CONTENT',
    friendSpace: 'Ganjaran Ruang Rakan',
    userGroup: 'Pangkalan pengguna rasmi',
    quickStartGuide: 'Panduan Mula Pantas WeeLife',
    ktvParty: 'Parti karaoke',
    creatorPlatform: 'Platform Kreator',
    tutorial: 'Tutorial'
  },
  webMeta: {
    title: 'weelife',
    titleCompany: 'Newlang 新言',
    description: 'Weelife is a cutting-edge virtual reality voice social product tailored for Generation Z. Combining high-quality gamified virtual elements with voice social interactions, it offers an unparalleled ultra-immersive social experience on both mobile and VR platforms. In Weelife world, users express their personalities through stylish avatars. Rich social interaction features make connecting with new friends easy and enjoyable.',
    descriptionCompany: 'Weelife is a cutting-edge virtual reality voice social product tailored for Generation Z. Combining high-quality gamified virtual elements with voice social interactions, it offers an unparalleled ultra-immersive social experience on both mobile and VR platforms. In Weelife world, users express their personalities through stylish avatars. Rich social interaction features make connecting with new friends easy and enjoyable.',
    keywords: 'Weelife;Weelife app;Weelifeapp;weelife;weelife app;weelifeapp;WeelifeApp',
    keywordsCompany: '新言;新言官网;广州新言;新言信息;广州新言信息;新言信息科技;广州新言信息科技;广州新言信息科技有限公司;newlang;newlang官网;newlang app;newlang App;Newlang'
  },
  errorPage404: {
    errMsg404: 'Ralat permintaan rangkaian, sumber tidak dijumpai!',
    btnText: 'Buka apl',
    MDialog: {
      headerText: 'Imbas kod QR untuk memuat turun Aplikasi weelife',
      btnText: 'Muat turun aplikasi'
    }
  },
  public: {
    downloading: 'Memuat turun...',
    wait: 'Sila tunggu...',
    openFail: 'Gagal buka APP, cuba bangkitkan semula'
  },
  footer: {
    priv: 'Dasar Privasi',
    userve: 'Perjanjian Pengguna',
    contact: 'Hubungi kami',
    email: 'Peti mel koperasi'
  },
  homePage: {
    content: {
      second: {
        content_1: 'Pakaian peribadi',
        content_2: 'Bersinar awak dan saya',
        content_3: 'Tunjukkan diri anda yang paling cantik kepada rakan-rakan anda'
      },
      third: {
        content_1: 'Ruang 3D',
        content_2: 'Luas sosial',
        content_3: 'Berbual dan bermain di ruang 3D dengan rakan anda'
      },
      four: {
        content_1: 'Bulatan baru kawan',
        content_2: 'Sentiasa bersama anda',
        content_3: 'Alami bulatan 3D rakan novel dan rasakan bentuk sosial yang berbeza'
      },
      five: {
        content_1: 'Nyanyian adalah baik',
        content_2: 'Watak sebenar penyanyi',
        content_3: 'Tunjukkan suara nyanyian anda di ruang KTV yang sejuk dan dapatkan tepukan!'
      },
      six: {
        content_1: 'Peta dengan peta',
        content_2: 'Terpulang kepada anda',
        content_3: 'Gunakan imaginasi anda untuk membuat peta aneh dan jemput rakan untuk melawat bersama ~'
      }
    }
  },
  login: {
    content: {
      content_1: 'Selamat datang ke Weelife',
      content_2: 'Terdapat mencubit muka, berpakaian, berbual dan berkawan, adegan sejuk, dan mencipta avatar yang unik',
      content_3: 'Log masuk dengan akaun dan kata laluan anda',
      content_4: 'Log masuk',
      content_5: 'Sila masukkan alamat e-mel berdaftar anda',
      content_6: 'Sila masukkan kata laluan anda'
    },
    error: {
      content_1: 'Pengguna tidak didaftarkan',
      content_2: 'Kata laluan yang dimasukkan tidak betul'
    },
    success: {
      tip_1: 'Log masuk berjaya'
    }
  },
  goods: {
    table: {
      title: 'Barang saya', // 我的商品
      content_1: 'Resource ID',
      content_2: 'Jenis Komponen',
      content_3: 'Masa Upload',
      content_4: 'Status Upload',
      empty: 'Sila log masuk ke Platform Kreator WeeLife melalui PC untuk upload resource',
      loading: 'Memuatkan...'
    },
    data: {
      content_2: 'Berjaya'
    }
  },
  music: {
    content: {
      content_1: 'Lagu ini telah dimuat naik',
      content_2: 'Ketua',
      content_3: 'dibuang',
      content_4: 'Muat naik lagu'
    },
    table: {
      content_1: 'Nama lagu',
      content_2: 'Penyanyi',
      content_3: 'Saiz',
      content_4: 'Masa muat naik',
      content_5: 'Muat naik status',
      loading: 'Memuatkan...'
    },
    logout: {
      content_1: 'Daftar keluar'
    },
    empty: {
      content_1: 'Tiada lagu telah dimuat naik'
    },
    upload: {
      content_1: 'Kembali ke halaman sebelumnya',
      content_2: 'Nama lagu',
      content_tip_2: '(diperlukan, tidak lebih daripada 20 perkataan)',
      content_3: 'Penyanyi',
      content_tip_3: '(diperlukan, tidak lebih daripada 20 perkataan)',
      content_4: 'Sumber Muzik',
      content_tip_4: '(Diperlukan, fail mestilah lebih besar daripada 512k dan tidak lebih daripada 20M, hanya format MP3 yang disokong)',
      content_5: 'Bahasa muzik',
      content_tip_5: '(Diperlukan)',
      content_6: 'Sahkan muat naik',
      content_7: 'Saya telah membaca dengan teliti dan bersetuju dengan perkara berikut "Peraturan Perkhidmatan Muat Naik dan Perkongsian Muzik Bilik Parti Weelife"',
      content_8: '(Sila baca perjanjian dan tandakan Setuju)',
      content_9: 'Muzik telah berjaya dimuat naik !!',
      content_10: 'Muat naik gagal, sila muat naik lagi !!'
    },
    tips: {
      content_1: 'Sila masukkan nama untuk lagu itu',
      content_2: 'Sila masukkan nama artis',
      content_3: 'Pilih fail',
      content_4: 'Menulis ganti fail',
      content_5: 'Sila pilih bahasa muzik',
      content_6: 'Sahkan muat naik',
      content_7: 'Anda mesti memasukkan nama lagu',
      content_8: 'Nama penyanyi mesti dimasukkan',
      content_9: 'Pilih fail',
      content_10: 'Menulis ganti fail',
      content_11: 'Tiada fail dipilih',
      content_12: 'Nama fail mengandungi aksara haram (aksara separuh lebar).',
      content_13: 'Genre muzik mesti dipilih',
      content_14: 'Format fail tidak sepadan, hanya format MP3 disokong'
    }
  },
  about: {
    intro: {
      title: 'Mengenai WeeLife:',
      content_1: 'Dengan berkat teknologi pengeluaran permainan generasi akan datang, Weelife menggabungkan avatar maya gamified berkualiti tinggi, adegan maya dan rangkaian sosial suara untuk membawa pengalaman sosial "kehadiran super" yang belum pernah terjadi sebelumnya kepada pengguna sosial di terminal mudah alih dan VR.',
      content_2: 'Di dunia Weelife, anda boleh menunjukkan keperibadian anda melalui imej yang indah dan padanan pakaian, permainan interaksi sosial yang kaya dan menarik, supaya ais sosial antara rakan-rakan baru boleh menjadi selesa dan semula jadi, pelbagai adegan sosial, atau hangat dan romantis atau mendebarkan, dan rakan-rakan dalam pengalaman umum untuk mewujudkan ikatan, suara sebenar, pergerakan badan yang jelas menjadikan proses interaksi lebih emosi dan hangat, dan juga membina adegan impian atau rumah anda sendiri untuk bermain dengan rakan-rakan. Matlamat Weelife adalah untuk membiarkan setiap pengguna dalam utopia yang bersemangat dan hangat ini, mereka boleh menuai gema suara mereka sendiri dan menghadapi ikatan jiwa mereka.',
      content_3: 'Weelife adalah produk muda yang perlu berkembang secara berterusan untuk memberi perkhidmatan yang lebih baik kepada majoriti pengguna. Sama ada anda seorang pemain permainan atau pencipta, tidak kira di mana anda berada di dunia, kami ingin mendengar daripada anda dan membantu kami membina utopia impian anda.'
    },
    culture: {
      title: 'Budaya Korporat',
      content_1: 'Terbuka dan telus',
      content_2: 'Mudah dan rendah hati',
      content_3: 'Cabar yang melampau',
      content_4: 'Bekerja keras untuk memulakan perniagaan'
    },
    welfare: {
      title: 'Manfaat'
    },
    contact: {
      title: 'Hubungi kami',
      content_1: 'Alamat: 402, Bangunan Qintian, No. 238 Zhongshan Avenue West, Daerah Tianhe, Guangzhou',
      content_2: 'Telefon: 020-66837100',
      content_3: 'Peti mel HR：'
    }
  },
  recharge: {
    header: 'Tambah nilai akaun anda',
    title: {
      title_1: 'Sila masukkan ID akaun anda',
      title_2: 'Pilih kaedah tambah nilai',
      title_3: 'Pilih jumlah yang dikreditkan'
    },
    box_1: {
      uid: 'ID Akaun',
      placeholder: 'Sila masukkan ID akaun anda',
      btn: 'Mengesahkan',
      switchText: 'Tukar akaun'
    },
    bottom: {
      price: 'Amaun yang perlu dibayar',
      pay: 'Membayar'
    },
    help: {
      header: 'Bagaimanakah saya menyemak ID akaun saya?',
      title_1: 'Bagaimanakah saya menyemak ID akaun saya?',
      title_2: 'Klik Salin di sebelah ID anda',
      confirm_btn: 'Faham!'
    },
    info: {
      header: 'Mesej butiran pesanan',
      title: 'Sila sahkan maklumat tambah nilai anda',
      nickname: 'Nama samaran',
      recharge: 'Jumlah tambah nilai',
      total_price: 'Jumlah harga',
      cancel: 'Batal',
      confirm: 'Adakah awak pasti'
    },
    loading: {
      pay_loading: 'Penghantaran Pesanan...'
    },
    error: {
      uid_not: 'ID akaun ini tidak wujud'
    },
    order: {
      submit_loading: 'Penghantaran Pesanan...',
      inquire_loading: 'Pemeriksaan pesanan...',
      success: 'Bayaran berjaya',
      success_info: 'Pesanan top-up berjaya! Terima kasih atas penggunaan dan sokongan anda, sila semak kembali ke weelife.',
      timed_out: 'Pemeriksaan pesanan timeout',
      timed_out_info: 'Pemeriksaan pesanan timeout. Klik Konfirmasi untuk melanjutkan periksa.',
      timed_out_info_two: 'Pemeriksaan pesanan timeout. Sila kembali ke Weelife-Pemberitahuan Sistem-Hubungi CS untuk menanyakan.'
    }
  },
  creator: {
    mygoods: 'Barang saya',
    tutorial: 'Tutorial',
    menswear: 'Pakaian Lelaki',
    womenswear: 'Pakaian Wanita',
    type_list: {
      TTF: 'Rambut',
      TTS: 'Aksesori Kepala',
      TMZ: 'Topi',
      TES: 'Anting',
      TMS: 'Aksesori Muka',
      TYJ: 'Cermin Mata',
      XSY: 'Baju',
      XWT: 'Kot',
      XJS: 'Rantai Leher',
      XBS: 'Aksesori Belakang',
      SST: 'Gloves',
      SSS: 'Aksesori Tangan',
      PKZ: 'Seluar',
      PJSK: 'Legging',
      PYS: 'Aksesori Pinggang',
      JWZ: 'Stoking',
      JXZ: 'Kasut',
      PWB: 'Ekor',
      SJZ: 'Cincin',
      SBS: 'Aksesori Lengan',
      XLYQB: 'Jubah',
      XLYQG: 'Gaun',
      PQZ: 'Skirt'
    }
  },
  creatorPlatform: {
    header: {
      logout: 'Log Keluar',
      tip: {
        text_1: 'Model pakaian mesti dibuat mengikut panduan, sila rujuk 【Tutorial】 untuk butiran lanjut.',
        text_2: 'Lihat Tutorial',
        text_3: 'Model yang dihantar mesti mematuhi 【Perjanjian Pengguna Platform】, jika tidak, ia tidak akan lulus semakan.',
        text_4: 'Lihat Perjanjian'
      }
    },
    table: {
      uploaded: 'Terupload',
      refresh: 'Refresh',
      upload_model: 'Upload Model',
      resourceId: 'Resource ID',
      type: 'Jenis Komponen',
      size: 'Saiz',
      uploadTime: 'Masa Upload',
      status: 'Status Upload',
      status_suc: 'Berjaya',
      status_err: 'Gagal',
      empty: 'Tiada pakaian yang diupload',
      loading: 'Memuatkan...'
    },
    upload_info: {
      back: 'Kembali',
      next: 'Seterusnya',
      confirm: 'Sahkan',
      steps: {
        text_1: '01 Klasifikasi Gender',
        text_2: '02 Klasifikasi Komponen',
        text_3: '03 Upload Resource'
      },
      from: {
        title: 'Apa yang anda ingin upload?',
        course: 'Lihat Tutorial',
        labels: {
          text_1: 'Pilih jenis yang anda ingin cipta',
          text_2: 'Pilih jenis pakaian yang ingin anda cipta'
        },
        dragger: {
          text_1: 'Tarik dan lepaskan di sini',
          text_2: 'atau upload lampiran',
          text_3: '1.Sila pastikan kategori pakaian betul, kerana ini akan mempengaruhi bagaimana item dipaparkan di mall.',
          text_4: '2.Sila pastikan model mematuhi spesifikasi. Untuk butiran lanjut, sila rujuk',
          text_5: '【Panduan Pengeluaran Pakaian】.'
        },
        upload_succ: 'Upload berjaya',
        upload_error: 'Upload gagal, sila cuba lagi',
        validator_1: 'Folder perlu mengandungi direktori "Models" dan "Textures", dan folder tidak boleh kosong.',
        validator_2: 'Di dalam folder "Models", sila simpan fail dengan sambungan .FBX.',
        validator_3: 'Di dalam folder "Textures", sila simpan fail dengan sambungan .TGA.',
        go_back_stop: 'Sumber belum dihantar. Adakah anda ingin batalkan penghantaran?'
      }
    }
  },
  language: {
    zh: '中文',
    'zh-Hant': '中文繁体',
    en: 'English',
    ar: 'العربية',
    in: 'Indonesia',
    ms: 'Melayu',
    tl: 'Filipino',
    vi: 'Tiếng Việt',
    th: 'ไทย',
    tr: 'Türkçe'
  },
  download: {
    btn: 'Muat turun sekarang'
  },
  popup: {
    cancel: 'Batal',
    confirm: 'Sahkan'
  },
  upload: {
    success: 'Terupload',
    loading: 'Sedang upload...',
    failed: 'Upload gagal, sila cuba lagi',
    repeat: 'Sedang upload! Jangan upload semula!',
    click_retry: 'Tap untuk upload semula',
    max_file: 'Jumlah fail tidak boleh lebih daripada {num} ya~',
    compressing: 'Compressing...',
    cancel: 'Batal',
    fail_message_1: 'Hanya satu folder boleh di-drag!',
    fail_message_2: 'Jumlah saiz fail tidak boleh melebihi 1GB.'
  }
}
