export default {
  route: {
    home: 'Home',
    music: 'Upload Music',
    about: 'About Us',
    upload: 'Upload',
    login: 'Land',
    privacy: 'Privacy',
    userve: 'User Agreement',
    recharge: 'Top up',
    udesk: 'Customer Service',
    quickGuide: "Weelife's Quick Start Guide",
    roomRewards: 'Join room for rewards',
    updateContent: 'UPDATE CONTENT',
    friendSpace: 'Friend Space Rewards',
    userGroup: 'Official User Group',
    quickStartGuide: "Weelife's Quick Start Guide",
    ktvParty: 'Karaoke Party',
    creatorPlatform: 'Creator Platform',
    tutorial: 'Tutorial'
  },
  webMeta: {
    title: 'weelife',
    titleCompany: 'Newlang 新言',
    description: 'Weelife is a cutting-edge virtual reality voice social product tailored for Generation Z. Combining high-quality gamified virtual elements with voice social interactions, it offers an unparalleled ultra-immersive social experience on both mobile and VR platforms. In Weelife world, users express their personalities through stylish avatars. Rich social interaction features make connecting with new friends easy and enjoyable.',
    descriptionCompany: 'Weelife is a cutting-edge virtual reality voice social product tailored for Generation Z. Combining high-quality gamified virtual elements with voice social interactions, it offers an unparalleled ultra-immersive social experience on both mobile and VR platforms. In Weelife world, users express their personalities through stylish avatars. Rich social interaction features make connecting with new friends easy and enjoyable.',
    keywords: 'Weelife;Weelife app;Weelifeapp;weelife;weelife app;weelifeapp;WeelifeApp',
    keywordsCompany: '新言;新言官网;广州新言;新言信息;广州新言信息;新言信息科技;广州新言信息科技;广州新言信息科技有限公司;newlang;newlang官网;newlang app;newlang App;Newlang'
  },
  errorPage404: {
    errMsg404: 'Network request error,the resource was not found!',
    btnText: 'Open App',
    MDialog: {
      headerText: 'Scan code to download Weelife',
      btnText: 'Download App'
    }
  },
  public: {
    downloading: 'Downloading...',
    wait: 'Please wait a moment...',
    openFail: 'Failed to open the app, attempting to recall'
  },
  footer: {
    priv: 'Privacy Policy',
    userve: 'User Agreement',
    contact: 'Contact Us',
    email: 'Cooperative Email'
  },
  homePage: {
    content: {
      second: {
        content_1: 'Personalized attire',
        content_2: 'Shine You and Me',
        content_3: 'Show your most beautiful self to your friends'
      },
      third: {
        content_1: '3D Space',
        content_2: 'Social Borderless',
        content_3: 'Chat and play in 3D space with friends'
      },
      four: {
        content_1: 'Novel Moments',
        content_2: 'Accompany always',
        content_3: 'Experience New 3D Moments，Feeling Different Social Styles'
      },
      five: {
        content_1: 'Be best at karaoke',
        content_2: 'Show off singing talent',
        content_3: 'Show off your singing voice in the cool KTV space，Harvest applause！'
      },
      six: {
        content_1: 'Picture the scene',
        content_2: 'You make the decision',
        content_3: 'Use your imagination to create fantastic maps and invite friends to visit together~'
      }
    }
  },
  login: {
    content: {
      content_1: 'Welcome to Weelife',
      content_2: 'You can pinch your face and change clothes 、Chat Dating、Tour cool scenes，Create a unique virtual image',
      content_3: 'Account password login',
      content_4: 'Log on',
      content_5: 'Please enter a registered email address',
      content_6: 'Please enter the password'
    },
    error: {
      content_1: 'User not registered',
      content_2: 'The password entered is incorrect'
    },
    success: {
      tip_1: 'login successful'
    }
  },
  goods: {
    table: {
      title: 'My items', // 我的商品
      content_1: 'Resource ID',
      content_2: 'Component Type',
      content_3: 'Upload Time',
      content_4: 'Upload Status',
      empty: 'Please log in to the WeeLife Creator Platform via PC to upload resources.',
      loading: 'Loading...'
    },
    data: {
      content_2: 'Component'
    }
  },
  music: {
    content: {
      content_1: 'Uploaded songs',
      content_2: 'Songs',
      content_3: 'Refresh',
      content_4: 'Upload songs'
    },
    table: {
      content_1: 'Song name',
      content_2: 'Singer',
      content_3: 'Size',
      content_4: 'Upload time',
      content_5: 'Upload status',
      loading: 'Loading...'
    },
    logout: {
      content_1: 'Log Out'
    },
    empty: {
      content_1: 'No songs uploaded yet'
    },
    upload: {
      content_1: 'Return to previous page',
      content_2: 'Song name',
      content_tip_2: '(Required, no more than 20 characters)',
      content_3: 'Singer',
      content_tip_3: '(Required, no more than 20 characters)',
      content_4: 'Music resources',
      content_tip_4: '(Mandatory, the file must be larger than 512k and no more than 20M, only MP3 format is supported)',
      content_5: 'Language of Music',
      content_tip_5: '(Required)',
      content_6: 'Confirm upload',
      content_7: 'I have carefully read and agree to the following "Weelife Party Room Music Upload and Sharing Service Rules"',
      content_8: "(Please read the agreement and check 'agree')",
      content_9: 'Successfully uploaded music!!!',
      content_10: 'Upload failed, please upload again!!!'
    },
    tips: {
      content_1: 'Please enter a song name',
      content_2: "Please input the singer's name",
      content_3: 'Select the file',
      content_4: 'Overwrite file',
      content_5: 'Please select the language of the music',
      content_6: 'Confirm upload',
      content_7: 'You must input a song name',
      content_8: 'You must input a singer name',
      content_9: 'Select the file',
      content_10: 'Overwrite file',
      content_11: 'No file selected',
      content_12: 'The file name contains illegal character(English half width symbol)',
      content_13: 'You must select a music type',
      content_14: 'The file format does not match, only MP3 format is supported'
    }
  },
  about: {
    intro: {
      title: 'About weelife',
      content_1: 'Weelife is a virtual reality voice social product specially created for Generation Z young people and taking the forefront of online social trends.With the support of next-generation game production technology, Weelife combines high-quality gamified virtual images, virtual scenes, and voice socializing.Weelife brings unprecedented "hyper presence" social experience to social users on mobile phones and VR platforms',
      content_2: 'In Weelife, players can showcase their personalized character designs through exquisite images and clothing combinations；Rich and interesting social interaction play methods, making the social interaction between new friends comfortable and natural；Diverse social scenes, whether warm, romantic or thrilling, establish bonds with friends through shared experiences；Real voice and vivid body movements make the interaction process more emotional and warm；You can even build your own dream scene or home to play with friends。The goal of Weelife is to enable every user to experience the echo of their voice and encounter the shackles of their soul in this dynamic and warm utopia。',
      content_3: 'Weelife is a young product that requires continuous growth to better serve its users。Whether you are a player or creator, no matter where you are in the world, we look forward to listening to your opinions and suggestions, and working together with us to build an ideal utopia。'
    },
    culture: {
      title: 'Corporate culture',
      content_1: 'Open and transparent',
      content_2: 'Simple and humble',
      content_3: 'Challenge the highest ',
      content_4: 'Start business practically'
    },
    welfare: {
      title: 'Welfare Treatment'
    },
    contact: {
      title: 'Contact Us',
      content_1: 'Address：402, Qintian Building, No. 238, Zhongshan Avenue West, Tianhe District, Guangzhou',
      content_2: 'Tel: 020-66837100',
      content_3: 'HR email：'
    }
  },
  recharge: {
    header: 'Account top-up',
    title: {
      title_1: 'Please enter your account ID',
      title_2: 'Select the store value method',
      title_3: 'Select the stored value amount'
    },
    box_1: {
      uid: 'Account ID',
      placeholder: 'Please enter your account ID',
      btn: 'Verify',
      switchText: 'Switch account'
    },
    bottom: {
      price: 'Amount payable',
      pay: 'Payment'
    },
    help: {
      header: 'How do I check my account ID?',
      title_1: 'Open the [Weelife] app and find the "My" screen',
      title_2: 'Click Copy next to your ID',
      confirm_btn: 'Know!'
    },
    info: {
      header: 'Order details message',
      title: 'Confirm your top-up details',
      nickname: 'Nickname',
      recharge: 'Recharge amount',
      total_price: 'Total price',
      cancel: 'Cancel',
      confirm: 'OK'
    },
    error: {
      uid_not: 'Please enter your account ID'
    },
    order: {
      submit_loading: 'Order submission...',
      inquire_loading: 'Order inquiry in progress...',
      success: 'Payment successful',
      success_info: 'Order recharge successful! Thank you for your use and support. Please return to Weelife to check',
      timed_out: 'Order inquiry timed out',
      timed_out_info: 'Order inquiry timed out. Click confirm to continue the inquiry',
      timed_out_info_two: 'Order inquiry timed out. Please return to weelife - System Notifications - Contact Customer Service for assistance'
    }
  },
  creator: {
    mygoods: 'My items',
    tutorial: 'Tutorial',
    menswear: 'Menswear',
    womenswear: 'Womenswear',
    type_list: {
      TTF: 'Hair',
      TTS: 'Headpiece',
      TMZ: 'Hat',
      TES: 'Earrings',
      TMS: 'Face Accessory',
      TYJ: 'Glasses',
      XSY: 'Top',
      XWT: 'Coat',
      XJS: 'Necklace',
      XBS: 'Back Accessory',
      SST: 'Gloves',
      SSS: 'Hand Accessories',
      PKZ: 'Pants',
      PJSK: 'Leggings',
      PYS: 'Waist Accessory',
      JWZ: 'Socks',
      JXZ: 'Shoes',
      PWB: 'Tail',
      SJZ: 'Ring',
      SBS: 'Arm Accessories',
      XLYQB: 'frock',
      XLYQG: 'Dress',
      PQZ: 'Skirt'
    }
  },
  creatorPlatform: {
    header: {
      logout: 'Log Out',
      tip: {
        text_1: '1. Clothing models must be created according to the guidelines. Please refer to the [Tutorial] for details.',
        text_2: 'View Tutorial',
        text_3: '2. Submitted models must comply with the [Platform User Agreement], otherwise they will not pass the review.',
        text_4: 'View User Agreement'
      }
    },
    table: {
      uploaded: 'Uploaded',
      refresh: 'Refresh',
      upload_model: 'Upload Model',
      resourceId: 'Resource ID',
      type: 'Component Type',
      size: 'Size',
      uploadTime: 'Upload Time',
      status: 'Upload Status',
      status_suc: 'Success',
      status_err: 'Failure',
      empty: 'No uploaded outfits available',
      loading: 'Loading...'
    },
    upload_info: {
      back: 'Previous Step',
      next: 'Next Step',
      confirm: 'Confirm',
      steps: {
        text_1: '01 Gender Classification',
        text_2: '02 Component Classification',
        text_3: '03 Resource Upload'
      },
      from: {
        title: 'What would you like to upload?',
        course: 'View Tutorial',
        labels: {
          text_1: 'Choose the type you want to create',
          text_2: 'Select the clothing type you want to create'
        },
        dragger: {
          text_1: 'Drag and drop here',
          text_2: 'or upload an attachment',
          text_3: '1. Please ensure the outfit category is correct, as this will affect how the item is displayed in the store.',
          text_4: '2. Please make sure the model meets the specifications. For details, refer to the',
          text_5: '[Clothing Production Guidelines].'
        },
        upload_succ: 'Upload successful',
        upload_error: 'Upload failed, please try again',
        validator_1: 'The folder must contain "Models" and "Textures" directories, and the folder cannot be empty.',
        validator_2: 'In the "Models" folder, please store files with the .FBX extension.',
        validator_3: 'In the "Textures" folder, please store files with the .TGA extension.',
        go_back_stop: 'The resources have not been submitted yet. Do you want to abandon the submission?'
      }
    }
  },
  language: {
    zh: '中文',
    'zh-Hant': '中文繁体',
    en: 'English',
    ar: 'العربية',
    in: 'Indonesia',
    ms: 'Melayu',
    tl: 'Filipino',
    vi: 'Tiếng Việt',
    th: 'ไทย',
    tr: 'Türkçe'
  },
  download: {
    btn: 'Download Now'
  },
  popup: {
    cancel: 'Cancel',
    confirm: 'OK'
  },
  upload: {
    success: 'Uploaded',
    loading: 'Uploading...',
    failed: 'Upload failed, please try again',
    repeat: 'Uploading! Please do not upload again!',
    click_retry: 'Tap to re-upload',
    max_file: 'The number of files cannot exceed {num}~',
    compressing: 'Compressing...',
    cancel: 'Cancel',
    fail_message_1: 'Only one folder can be dragged in!',
    fail_message_2: 'The total file size must not exceed 1GB.'
  }
}
